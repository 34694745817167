body
	background-color: #242c3e;
	height 6400px
	font-family 'Open Sans'

	.test-layer-bg
		background-image url(./../img/drawing.jpg);
		width: 5000px;
		height: 5034px;
		background-position: -200px -1200px

	.content
		position fixed
		max-width 1170px
		margin 0 auto;
		background rgba(#fff, 0.8)
		z-index 1
		padding 30px
		border-radius 10px
		box-shadow 0 0 30px rgba(#000, 0.4)

	.test-layer
		width: 10000px;
		height: 10000px;

		.test-layer-blocks
			position absolute

